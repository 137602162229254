<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        type: {
            type: String,
            default() {
                return 'line'
            }
        },
        datasets: {
            type: Array,
            require: true
        },
        labels: {
            type: Array,
            require: true,
        },
        options: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            chart: null,
            data: {
                type: 'line',
                data: {
                    datasets: [],
                    labels: []
                },
                options: {}
            },

        }
    },

    methods: {
        prepare() {
            if(this.chart) {
                this.chart.destroy()
            }
            this.data.type = this.type
            this.data.data.datasets = this.datasets
            this.data.data.labels = this.labels
            this.data.options = this.options
            this.init()
        },

        init() {
            var ctx = this.$el.querySelector('canvas').getContext('2d')
            this.chart = new Chart(ctx, this.data)
        },

        update() {
            this.chart.data.labels.pop();
            this.chart.data.datasets.forEach((dataset) => {
                dataset.data.pop();
            });
            this.chartsets.forEach()
            this.chart.data.datasets = this.chartsets
            this.chart.data.labels = this.labels
            this.chart.options = this.options
            this.chart.update();
        }
    },
    // updated() {
    //     this.prepare()
    // },
    mounted() {
        // this.prepare()
    },
}
</script>

<template>
    <div>
        <canvas :id="_uid" width="400" height="300"></canvas>
    </div>
</template>
<style lang="scss" scoped>
    canvas{
        max-height: 300px;
    }
</style>

